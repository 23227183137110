//引入组件页面
import pagesUser from './views/user';
import pagesAgent from './views/agent';
import pagesCommon from './views/common';
import pagesKey from './views/key';
import agentReport from "./views/agentReport";
import userReport from "./views/userReport";
import log from './views/log';

//引入404页面
import notFound from "../views/error/notFound";
const pages = [
    {
        //404页面
        path: '/:pathMatch(.*)*',
        name:'notFound',
        component:notFound
    },
    //首页
    // {
    //     path: '/',
    //     name:'website.index',
    //     component: ()=>import('../views/common/Index')
    // },
    //登录页面
    {
        path:'/:version',
        alias:'/login/:version',
        name:'login',
        component:()=>import('../views/login/index'),
        props: true
    },
    //后台布局
    {
        path: '/member/',
        alias: '/system',
        name: 'member',
        component:()=>import('../Layout/Member'),
        children:[
            {
                path:'index',
                name:'member.index',
                component:()=>import('../Layout/components/indexData'),
            },
            //引入user组件路由
            ...pagesUser,
            //agent组件
            ...pagesAgent,
            //common组件[公共页面]
            ...pagesCommon,
            //卡密模块
            ...pagesKey,
            //代理统计
            ...agentReport,
            //用户统计
            ...userReport,
            //日志模块
            ...log,
        ],
    },

];

export default pages;