const lang = {
    //用户列表
    'userList':{
        'html.el-button.refresh':'刷新页面',
        'html.el-button.view.all':'显示全部',
        'html.el-button.view.filter':'显示筛选',
        'html.el-checkbox.userDataType':'跨级管理',
        'html.el-table-column.label.agent':'代理商',
        'html.el-table-column.label.username':'账号',
        'html.el-table-column.label.name':'名字',
        'html.el-table-column.label.nickname':'备注',
        'html.el-table-column.label.reg_time':'注册时间',
        'html.el-table-column.label.login_time':'最后登录',
        'html.el-table-column.label.money':'余额',
        'html.el-table-column.label.contact':'联系方式',
        'html.el-table-column.label.status':'状态',
        'html.el-table-column.label.status.1':'可用',
        'html.el-table-column.label.status.default':'禁用',
        'html.el-table-column.label.tools':'管理',
        'html.el-dropdown-item.tableMenu.1':'编辑信息',
        'html.el-dropdown-item.tableMenu.2':'充值余额',
        'html.el-dropdown-item.tableMenu.3':'回收余额',
        'html.el-dropdown-item.tableMenu.4':'查询下载',
        'html.el-dropdown-item.tableMenu.5':'充值记录',

        'getUserList.wait':'正在加载用户列表...',
        'getUserList.error.userList':'用户列表加载异常',
        'getUserList.error.data':'请求返回数据异常',
        'getUserList.tips.userNum':'用户列表已加载 数量:',
        'editUser.error.userid':'用户id异常',
        'viewTableData.wait':'正在切换..',
        'viewTableData.tips.filterNum':'[只显示前200条]筛选完成,符合结果数:',
        'getUserMoneyTime.timeout':'已过期',
        'getUserMoneyTime.day':'天',
        'changeUserDataType.wait':'正在切换用户数据源...',

        'html.el-button.outputData':'导出数据',
        'updateTable.message':'您已更改用户数据,是否更新显示表格数据?',
        'updateTable.title':'即将刷新页面',
        'updateTable.catch':'您已取消刷新表格,最新数据将会在您刷新后显示',
        'updateArr.tips':'表格数据已更新',
        'outputData.message':'请选择要导出的类型',
        'outputData.confirmButtonText':'导出全部',
        'outputData.cancelButtonText':'导出勾选',
        'makeOutPutUserData.error.length':'导出数据为空,请检查',
        'makeOutPutUserData.tips.length':'正在生成导出数据,数据长度:',
    },

    //用户列表 - 菜单
    'userList-menu':{
        'menu.recovery':'回收用户',
        'menu.move':'迁移用户',
        'menu.filter':'筛选用户',
        'menu.output':'导出用户',
        'menu.addUserMoney':'批量充值',
        'error.length':'请选择要操作的用户',
        'makeOutPutData.wait': '正在生成导出数据,请稍后',
        'makeOutPutData.successful': '数据已生成完毕',
        'makeOutPutData.length': '请您确认要导出的用户',
        'error.max':'操作用户超过数量限制'
    },

    //批量注册用户
    'userBulk':{
        'form.user':'账号前缀',
        'form.start':'后缀起始',
        'form.padding':'后缀长度',
        'form.num':'注册数量',
        'form.name':'注册姓名',
        'form.contact':'联系方式',
        'form.tips.user':'注册账号范围',
        'form.submit':'提交请求',
        'form.reset':'重置数据',
        'el-tag.tips':'提示:注册可能需要几十秒时间,请耐心等待!请勿重复点击!',
        'el-tag.tips.warning':'注意事项:用户信息仅英文数字可用,账号长度限制:1~16',
        'submit.error.form':'请填写完整信息',
        'submit.error.num':'注册范围只允许1~2000',
        'submit.wait':'正在提交请求,请勿重复点击..',
        'submit.success':'批量注册成功',
        'submit.res.code.un':'执行失败,未知错误!',
        'submit.res.code.1':'[卡密]注册码不足',
        'submit.res.code.2':'执行异常[db]',
        'submit.res.code.3':'卡密模块异常',
        'submit.res.code.4':'账号重复',
        'submit.res.code.500':'提交参数不符合规则',
        'openDownLoadUserData.wait':'正在生成导出用户信息..',
        'openDownLoadUserData.error.userData':'执行完毕,但取出注册账号信息异常,请到用户列表查看注册成功的用户信息',
        'openDownLoadUserData.successful':'已生成用户信息',
    },
    //批量迁移用户
    'userMove':{
        'html.select.placeholder':'迁移的新代理',
        'html.button.add':'添加用户',
        'html.button.reset':'清空用户',
        'html.button.submit':'执行迁移',
        'html.el-popconfirm.submit':'是否执行迁移用户?',
        'html.tag.userNum':'用户数',
        'html.dialog.title':'[新增用户]一行一个',
        'html.dialog.placeholder':'请输入要迁移的用户,一行一个',
        'html.dialog.footer.addUser':'添加',
        'html.dialog.footer.return':'取消',
        'addUser.error.user':'请输入要添加的用户',
        'submit.error.user':'请输入要迁移的用户',
        'submit.error.agent':'请选中要迁移的新代理商账号',
        'submit.wait':'正在执行...请稍后',
        'submit.success':'执行成功',
        'submit.res.errorCode.1':'部分用户不存在 / 部分用户不是您旗下的 ',
        'submit.res.errorCode.2':'代理商不存在',
        'submit.res.errorCode.3':'执行异常',//[update]生成Sql语句失败
        'submit.res.errorCode.4':'执行失败[db]',
        'submit.res.errorCode.5':'迁移用户数超过数量限制',
    },
    //批量回收用户
    'recovery':{
        'html.el-input.placeholder':'请输入等待操作的用户列表..',
        'html.el-tag.tips':'提示:迁移过程可能需要十几秒,请耐心等待..',
        'html-el-popconfirm.submit':'是否执行批量回收用户?',
        'html.button.submit':'提交数据',
        'html.button.reset':'清空数据',
        'html.el-tag.userNum':'用户数',
        'submit.error.user':'请输入要迁移的用户',
        'submit.tips.wait':'正在迁移用户..请稍后',
        'submit.res.code.un':'*未知错误',
        'submit.res.code.100':'执行成功,正在解析结果..请稍后',
        'submit.res.code.1':'解析用户列表异常',
        'submit.res.code.2':'超过数量限制[20]',
        'submit.error.result':'执行成功,但是解析结果列表异常',

        'submit.title':'回收账号结果',
        'submit.res.state.100':'成功',
        'submit.res.state.1':'[失败]用户不存在 ',
        'submit.res.state.2':'[失败]用户超过回收期限[切割超过7天] ',
        'submit.res.state.3':'[失败]查找使用的卡密失败',
        'submit.res.state.4':'[失败]重置卡密失败',
        'submit.res.state.5':'[失败]写入异常[db]',
        'submit.res.state.un':'[失败]未知错误[异常]',
        'submit.jump.wait':'正在跳转结果页面,请稍后..',
    },
    //[用户列表](充值/回收)用户余额
    'userList-changeMoney':{
        'submit.res.code.100':'执行成功',
        'submit.res.code.1':'用户不存在',
        'submit.res.code.2':'查询代理商信息异常',
        'submit.res.code.3':'用户所属代理商不正确',
        'submit.res.code.4':'[代理商]余额不足',
        'submit.res.code.5':'[用户]余额不足',
        'submit.res.code.6':'写入异常[db]',
        'submit.res.code.7':'提交参数不符合规则[1~5000]',
        'html.form.username':'账号',
        'html.form.money':'金额',
        'html.form.type':'操作类型',
        'html.form.type.add':'充值余额',
        'html.form.type.reduce':'回收余额',
        'html.form.selfMoney':'当前用户余额',
        'html.button.return':'取消操作',
        'html.button.submit':'提交请求',
        'submit.wait':'正在执行请求..',
        'getUserInfo.error':'加载用户余额异常',
    },
    //用户下载记录
    'userDownLoad':{
        'html.button.return':'返回上级',
        'html.button.refresh':'刷新页面',
        'html.button.outFileByUserDownload':'导出记录',
        'getCount.wait':'正在获取记录总数..',
        'getCount.error':'获取记录总数异常',
        'getTableData.wait':'正在查询下载记录..',
        'getTableData.error.1':'[*]获取下载记录异常',
        'getTableData.error.2':'[**]获取下载记录异常',
        'outFileByUserDownload.wait':'正在准备导出数据...',
        'outFileByUserDownload.successful':'导出数据已完成',

    },
    //[用户列表]筛选器
    'userList-filter':{
        'html.el-form-item.label.time':'注册时间',
        'html.el-form-item.label.username':'用户名',
        'html.el-form-item.label.name':'名字',
        'html.el-form-item.label.nickname':'备注',
        'html.span.tips':'提示:只需要填写一个筛选条件!',
        'html.el-button.submit':'筛选',
        'html.el-button.return':'取消',
        'html.el-button.reset':'清空',

        'submit.error.filterValue':'请设置需要筛选的条件!',
        'submit.wait':'正在筛选用户...',
    },

    //[用户列表]编辑/查看个人信息
    'userList-info':{
        'html.el-form-item.label.agent':'所属代理商',
        'html.el-form-item.label.username':'用户名',
        'html.el-form-item.label.password':'密码',
        'html.el-form-item.label.name':'名字',
        'html.el-form-item.label.nickname':'备注',
        'html.el-form-item.label.contact':'联系方式',
        'html.el-form-item.label.status':'状态',
        'html.el-radio-group.status.1':'可用',
        'html.el-radio-group.status.0':'禁用',
        'html.el-form-item.label.time':'时间',
        'html.el-tag.login_time':'登录',
        'html.el-tag.reg_time':'注册',
        'html.el-form-item.label.money':'余额',
        'html.el-button.submit':'提交请求',
        'html.el-button.return':'取消修改',

        'submit.tips':'您未做任何修改,无需提交请求',
        'submit.wait':'正在提交数据..',
        'submit.successful':'修改成功!',
        'submit.res.error.2':'修改数据不符合规则',
        'submit.res.error.default':'执行错误',
    },

    //用户使用充值码卡密记录
    'userUseRecKey':{
        'html.el-button.go.-1':'返回上级',
        'html.el-button.go.0':'刷新页面',
        'html.el-tag.user':'查询用户',
        'html.el-table-column.label.id':'条形码',
        'html.el-table-column.label.key':'卡密',
        'html.el-table-column.label.money':'额度',
        'html.el-table-column.label.time':'时间',

        'getUseKeyData.wait':'正在加载数据..',
        'getUseKeyData.error.res':'查询记录异常',
        'getUseKeyData.error.length':'该用户无记录',
    },

    //用户批量充值
    'userAddMoneyBatch':{
        'submit.code.100':'执行成功',
        'submit.code.1':'解析充值列表异常',
        'submit.code.2':'部分用户不符合规则[不存在/所属代理商不正确]',
        'submit.code.3':'超过数量限制[1000]',
        'submit.code.4':'代理商余额不足',
        'submit.code.5':'执行异常[5]',
        'submit.code.6':'执行异常[6]',
        'submit.error.list':'请输入待充值的用户列表',
        'submit.wait':'正在提交请求...',

        'html.input.placeholder':'请输入需要充值的用户列表~',
        'html.popconfirm.title':'是否执行操作?',
        'html.button.submit':'提交请求',
        'html.button.reset':'重置数据',
        'html.tag.userNum':'用户数',
        'html.tag.money':'余额[充值]',
        'html.tag.tips':'[一行一条]输入格式:用户名----充值额度(整数)',
    },
    'userList-filterMoneyTime':{
        'html.button.filter':'筛选余额',
        'html.dropdown-item.day':'筛选天数',
        'html.dropdown-item.overdue':'筛选到期',
        'html.button.return':'取消操作',
        'html.button.submit':'执行筛选',
        'filterUser.wait':'正在筛选用户中...',
        'filterUser.success':'符合条件用户数:',
    },
    'BatchRegUserLog':{
        'html.DownLoadLog.Describe':'批量注册用户记录'
    }
};
export default lang;