const lang = {
    //添加下级代理商
    'addAgent' : {
        'form.user' : '账号',
        'form.pass' : '密码',
        'form.name' : '名字',
        'form.contact' : '联系方式',
        'button.submit' : '添加下级',
        'button.reset' : '清空表单',
        'errorMsg.form':'请输入完整下级代理信息',
        'errorMsg.axios':'发送请求异常',
        'msg.wait':'正在添加下级代理...',
        'msg.succ':'添加下级成功',
        'res.code.1' : '参数不符合规则',
        'res.code.2' : '账号已存在',
        'res.code.3' : '添加异常',
        'res.code.un' : '添加失败',
    },
    //[充值/回收]代理商金额
    'agentListChangeMoney' : {
        'form.money' : '变动金额:',
        'form.moneyType.label' : '金额类型:',
        'form.moneyType.1' : '(余额)注册',
        'form.moneyType.2' : '(余额)充值',
        'form.info.label' : '下级信息:',
        'form.info.reg' : '注册',
        'form.info.rec' : '充值',
        'form.changeType.label' : '操作类型',
        'form.changeType.addMoney' : '充值下级',
        'form.changeType.recovery' : '回收下级',
        'form.submit' : '提交请求',
        'form.return' : '取消操作',
        'openDialog.user':'用户名:',
        'submit.error.money':'操作金额范围不正确[1~300000]',
        'submit.succ':'执行成功!',
        'submit.code.un':'未知错误',
        'submit.code.1':'[changType/moneyType/moneyType]不正确',
        'submit.code.2':'agentId不正确',
        'submit.code.3':'代理不存在',
        'submit.code.4':'余额不足',
        'submit.code.5':'执行异常[db]',
        'submit.code.6':'不是您的下级',
    },
    //[代理列表]筛选器
    'agentList-filter':{
        'html.el-dialog.title':'请输入筛选条件',
        'html.el-form-item.label.time':'注册时间',
        'html.el-form-item.label.username':'代理账号',
        'html.el-form-item.label.name':'名字',
        'html.el-form-item.label.nickname':'备注',
        'html.span.tips':'提示:只需要填写一个筛选条件!',
        'html.el-button.submit':'筛选',
        'html.el-button.return':'取消',
        'html.el-button.reset':'清空',
        'submit.error.filter':'请设置需要筛选的条件',
        'submit.wait':'正在筛选代理...',
    },
    //[代理列表]代理信息[查看/编辑]
    'agentList-info':{
        'html.el-form-item.label.leader':'上级:',
        'html.el-form-item.label.username':'用户名:',
        'html.el-form-item.label.password':'密码:',
        'html.el-form-item.label.name':'名字:',
        'html.el-form-item.label.nickname':'备注:',
        'html.el-form-item.label.contact':'联系方式:',
        'html.el-form-item.label.status':'状态:',
        'html.el-radio.1':'可用',
        'html.el-radio.0':'禁用',
        'html.el-form-item.label.time':'时间:',
        'html.el-form-item.label.login_time':'登录:',
        'html.el-form-item.label.reg_time':'注册:',
        'html.el-form-item.label.money_reg':'余额[注册]:',
        'html.el-form-item.label.money_rec':'余额[充值]:',
        'html.el-button.submit':'提交修改',
        'html.el-button.return':'取消修改',
        'submit.tips':'您未做任何修改,无需提交请求',
        'submit.wait':'正在提交数据..',
        'submit.successful':'修改成功!',
        'submit.res.code.2':'修改数据不符合规则',
        'submit.fail':'执行错误',
    },
    //代理列表
    'agentList':{
        'html.el-button.refresh':'刷新页面',
        'html.el-button.viewTableData.1':'显示全部',
        'html.el-button.viewTableData.2':'显示筛选',
        'html.el-button.openFilter':'筛选数据',
        'html.el-checkbox.AgentDataType':'跨级操作',
        'html.el-table-column.label.username':'账号',
        'html.el-table-column.label.name':'姓名',
        'html.el-table-column.label.contact':'联系方式',
        'html.el-table-column.label.nickname':'备注',
        'html.el-table-column.label.money_reg':'余额[注册]',
        'html.el-table-column.label.money_rec':'余额[充值]',
        'html.el-table-column.label.leader':'上级',
        'html.el-table-column.label.reg_time':'注册时间',
        'html.el-table-column.label.login_time':'最后登录',
        'html.el-table-column.label.status':'状态',
        'html.el-table.template.status.1':'可用',
        'html.el-table.template.status.0':'禁用',
        'html.el-table.tools':'管理',
        'html.el-dropdown-item.tableMenu.1':'编辑信息',
        'html.el-dropdown-item.tableMenu.2':'充值余额',
        // 'html.el-dropdown-item.tableMenu.3':'日志查询',
        'html.el-dropdown-item.tableMenu.4':'回收余额',
        'html.el-dropdown-item.tableMenu.5':'查询卡密',
        'html.el-dropdown-item.tableMenu.6':'用户管理',
        'html.el-dropdown-item.tableMenu.7':'日志查询',

        'getAgentList.wait':'正在加载代理列表...',
        'getAgentList.error':'加载代理商数据异常',
        'getAgentList.tips.num':'代理商列表已加载 数量:',
        'viewTableData.wait':'正在切换..',
        'viewTableData.tips':'[只显示前200条]筛选完成,符合结果数:',
        'changeAgentDataType.wait':'正在切换跨级数据...',

        'html.el-button.outputData':'导出数据',
        'updateTable.message':'您已更改用户数据,是否更新显示表格数据?',
        'updateTable.title':'即将刷新页面',
        'updateTable.catch':'您已取消刷新表格,最新数据将会在您刷新后显示',
        'updateArr.tips':'表格数据已更新',
        'outputData.message':'请选择要导出的类型',
        'outputData.confirmButtonText':'导出全部',
        'outputData.cancelButtonText':'导出勾选',
        'makeOutPutUserData.error.length':'导出数据为空,请检查',
        'makeOutPutUserData.tips.length':'正在生成导出数据,数据长度:',
    },

    //修改自己密码页面
    'setSelfNewPass':{
        'html.el-form-item.newPass':'新密码',
        'html.el-form-item.tempPass':'二次确认',
        'html.button.submit':'提交请求',
        'html.button.reset':'重置数据',

        'submit.error.length':'请输入要设置的密码',
        'submit.error.tempPass':'两次输入密码不一致,请检查',
        'submit.wait':'正在提交请求...',
        'submit.successful':'执行成功',
    },

    //下级代理商用户列表
    'subordinateUsers':{
        'moveUserToSelf.error.userList':'请选择要操作的用户',
        'moveUserToSelf.error.max':'操作数量超过最大限制',
        'moveUserToSelf.wait':'正在提交请求,请稍后...[*执行期间请勿关闭本页面]',
        'moveUserToSelf.res.code.1':'部分用户不存在/部分用户不是您旗下的',
        'moveUserToSelf.res.code.2':'询代理商信息异常',
        'moveUserToSelf.res.code.3':'执行异常[update]',
        'moveUserToSelf.res.code.4':'执行异常[db]',
        'moveUserToSelf.res.code.5':'超过数量限制',
        'moveUserToSelf.res.code.6':'提交参数不正确',
        'moveUserToSelf.res.code.7':'该代理商不是您的下属',
        'moveUserToSelf.res.code.100':'执行成功,请手动刷新页面查看',
        'getAgentUserList.wait':'正在获取用户列表...',
        'getAgentUserList.error.userData':'获取用户列表异常',
        'getAgentUserList.res.code.1':'查询代理信息异常',
        'getAgentUserList.res.code.2':'不是您的下级代理商',
        'watchFilterData.success':'筛查完成!正在渲染数据..符合结果数量:',

        'html.el-button.refresh':'刷新页面',
        'html.el-button.return':'返回上级',
        'html.el-button.filter':'筛选数据',
        'html.el-button.moveUser':'回迁选中用户',
        'html.el-popconfirm.title':'是否执行操作?',
        'html.el-tag.agent':'当前代理商',
        'html.el-table-column.label.id':'编号',
        'html.el-table-column.label.username':'账号',
        'html.el-table-column.label.nickname':'备注',
        'html.el-table-column.label.name':'名字',
        'html.el-table-column.label.money':'余额',
        'html.el-table-column.label.login_time':'最后登录',
        'html.el-table-column.label.reg_time':'注册时间',
        'html.el-table-column.label.status':'状态',
    },
};
export default lang;