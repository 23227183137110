const lang = {
    //Agent log
    'agentLog':{
        'html.el-button.refresh': 'Refresh the page',
        'html.el-tag.agent':'Query agent',
        'html.el-table.label.id':'Number',
        'html.el-table.label.time': 'time',

        'html.el-table.label.object': 'Operation target',
        'html.el-table.label.type': 'Message Type',
        'html.el-table.label.attach': 'attached content',

        'getAgentLog.wait': 'Requesting agent log..',
        'getAgentLog.error.data': 'Query agent data error',
        'getAgentLog.error.length': 'The query agent log is empty',

        'logType.1': 'Add a subordinate agent',
        'logType.2': 'Modify subordinate agent information',
        'logType.3': 'Modify own password',
        'logType.4': 'Recharge subordinate balance [registration/recharge]',
        'logType.5': 'Recover subordinate balance [registration/recharge]',
        'logType.6': 'Superior recharged balance [registration/recharge]',
        'logType.7': 'Superior recovered balance [registration/recharge]',
        'logType.8': 'Migration code',
        'logType.9': 'Recycle code',
        'logType.10': 'Bulk generated code',
        'logType.11': 'Recycle code',
        'logType.12': '[User balance] recharge',
        'logType.13': '[User balance] Recycling',
        'logType.14': 'Bulk recharge user balance',
        'logType.15': '[Single/bulk] Recycle users',
        'logType.16': '[single/bulk] register users',
        'logType.17': 'Modify user information',
        'logType.18': 'The agent to which the user belongs is changed [migrate user/recycle user]',
        'logType.un': '*? Unknown log type [please contact customer service]',
        //easy language log type [more than 100]
        'logType.100': 'Add a subordinate agent',
        'logType.101': 'General distribution',
        'logType.102': 'Number of authorized users',
        'logType.103': 'Allocate account amount',
        'logType.104': 'Increase account amount',
        'logType.105': 'Generate registration code',
        'logType.106': 'Generation time code',
        'logType.107': 'Allocation quota maount',
        'logType.108': 'Balance transcoding [registration/recharge] code',
        'logType.109': 'Increase the number of times',
        'logType.110': 'Number of days to allocate amount',
        'logType.111': 'Balance transcoding times and days recharge code',
        'logType.112': 'Migrated user',
        'logType.113': '[Registration code]  Retrieve code',
        'logType.114': '[Registration Code] [New] Retrieve code',
        'logType.115': 'Retrieve user',
        'logType.116': 'Allot the code under the registration code',
        'logType.117': 'Allot the code under the recharge code',
        'logType.118': '[Recharge code] Move back code',
        'logType.119': 'Added the number of code for the recharge code',
        'logType.120': 'User recharge',
        'logType.121': 'Recover subordinate amount',
        'logType.122': 'Superior recycling code',
        'logType.123': 'Recycle own code',
        'logType.124': 'Recover user balance',
        'logType.125': 'Bulk recharge users',
    },
};
export default lang;