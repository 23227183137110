/**
 * 一些封装好的共用函数
 */
import { ElMessageBox } from 'element-plus';
import vuex from "../Vuex";
import router from "../router";
const fun = {
    /**
     * 调用浏览器输出文件[下载文件]
     * @param content 文件内容
     * @param filename 文件名
     */
    downLoad(content,filename){
        var ele = document.createElement('a');// 创建下载链接
        ele.download = filename;//设置下载的名称
        ele.style.display = 'none';// 隐藏的可下载链接
        // 字符内容转变成blob地址
        var blob = new Blob([content]);
        ele.href = URL.createObjectURL(blob);
        // 绑定点击时间
        document.body.appendChild(ele);
        ele.click();
        // 然后移除
        document.body.removeChild(ele);
    },

    /**
     * 检查是否调用Api成功 [检查内容:返回数据/Http状态码/code字段是否为100]
     * @param response axios返回的数据[res]
     * @param alert 是否弹出错误代码提示框
     * @returns {boolean|{code}|*}
     * 成功返回数据包 失败返回false
     */
    checkRes(response,alert = false){
        // console.log('checkRes',response)
        //检测返回[code]字段 和 状态码
        let data = response.data;
        if (data == null || response.status != 200){
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!');
            console.log('checkRes->不通过',response,data);
            if (alert){
                ElMessageBox.alert('Error Code: Null',"Call failed",{type:'error'});
            }
            return false;
        }
        //检测返回的数据[code]字段是否为100
        let code = data.code ?? -1;
        if (code == 100){
            return data;
        }
        if (alert){
            ElMessageBox.alert('Error Code:' + code,"Call failed",{type:'error'});
        }
        return false;
    },

    /**
     * 取调用Api返回的错误代码
     * @param response axios返回的数据[res]
     * @returns {number|*}
     * 成功返回错误代码 失败返回-1
     */
    getResErrorCode(response){
        // console.log('getResErrorCode',response);
        let data = response.data;
        let code = data.code == null ? -1 : data.code;
        return code;
    },

    /**
     * [报错函数]axios.catch
     */
    axiosCatch(error){
        console.log('axiosCatch',error);
        ElMessageBox.alert('HTTP send request failed',"Error",{type:'error'})
    },

    /**
     * [多语言]弹出错误代码与错误信息
     * @param lang 多语言库
     * @param errorLangPrefix (多语言)错误代码语言字段前缀
     * @param code 错误代码
     * @param defalutLang (多语言)错误代码消息多语言不存在时查找这个默认的错误消息
     * @param defalutMsg (参数:defalutLang)不存在时弹出这个消息
     */
    alertErrMsg(lang,errorLangPrefix,code,defalutLang = 'un',defalutMsg = 'Execution failed'){
        let field  = errorLangPrefix + code;
        let msg = 'error';
        if (lang[field] == null){
            //错误代码消息不存在,则获取默认的错误消息多语言
            if (lang[defalutLang] == null){
                //连默认的错误消息多语言都不存在,则直接提示固定的错误消息
                msg = defalutMsg;
            }
        }else{
            //多语言存在,就按照多语言的设定来
            msg = lang[field];
        }
        ElMessageBox.alert(msg,"Error Code:" + code,{
            type:'error'
        });
    },

    /**
     * 检查父字符串是否存在子字符串[不区分大小写]
     * @param str 父字符串
     * @param findStr 查找的字符串[子字符串]
     * @returns {boolean}
     */
    checkExistString(str,findStr){
        // let res = str.indexOf(findStr);
        // if (res != -1){
        //     return true;
        // }
        let temp = this.replaceIllegalChar(str);
        let obj = new RegExp(findStr,'i');
        let res = str.match(obj);
        if (res != null){
            return true;
        }
        return temp.match(obj) != null;
    },

    /**
     * 检查是否在某个时间范围内
     * @param startTime 起始时间
     * @param endTime 结束时间
     * @param targetTime 待检查的时间
     * @returns {boolean}
     * 在范围内返回TRUE
     */
    isTimeFrame(startTime,endTime,targetTime){
        /**
         * 时间字符串转时间戳
         * @param time [时间]字符串
         * @returns {number}
         * 成功返回时间戳 失败返回0
         */
        let fun = (time)=>{
            let date = new Date(time);
            let timeStamp = date.getTime();
            let res = timeStamp ? timeStamp : 0;
            return res;
        }
        let start = fun(startTime),end = fun(endTime),target = fun(targetTime);
        //检查是否转换时间戳成功
        let bool = start && end && target;
        if (!bool){
            console.log('isTimeFrame->创建时间戳失败',start,end,target,'bool',bool);
            return false;
        }
        //检查是否符合范围
        bool = target > start && target < end;
        return bool;
    },

    /**
     * [result.vue]跳转到结果页面
     * @param resultArr 结果数组
     * @param lang 多语言
     * @param langCodeFiled 结果字段前缀
     * @param title 结果页面标题
     */
    jumpResultPage(resultArr,lang,langCodeFiled,title){
        console.log('jumpResultPage',resultArr,lang,langCodeFiled);
        let obj = {
            data:resultArr,
            lang:lang,
            langCodeField:langCodeFiled,
            title
        };
        // this.$store.commit('common/set_result',obj);
        vuex.commit('common/set_result',obj);
        // this.$router.push({name:'common.result'})
        router.push({name:'common.result'})
    },

    /**
     * 前补零（str不能为空，否则会少一位TT）
     * @param str 字符串
     * @param n 总长度(n-字符串长度 = 要补0的数量]
     * @returns {string}
     */
    fixZeroStart(str, n) {
        if (str.length >= n){
            //如果总长度小于字符串的长度,则直接返回原字符串
            return str;
        }
        return (Array(n).join(0) + str).slice(-n);
    },

    /**
     * [替换非法字符]
     * 基于规则过滤字符串,不符合规则将会被替换
     * @param str
     * @returns {*}
     * 返回[汉字,英文,数字,@,_,-]六种字符,其他统一替换成[_]
     */
    replaceIllegalChar(str) {
        return str.replace(/[^\w\u4e00-\u9fa5@-]/g, '_');
    }



}

export default fun;