const lang = {
    //Add subordinate agents
    'addAgent' : {
        'form.user' : 'Account',
        'form.pass' : 'password',
        'form.name' : 'name',
        'form.contact' : 'contact details',
        'button.submit' : 'Add subordinate',
        'button.reset' : 'Clear the form',
        'errorMsg.form': 'Please enter complete subordinate agent information',
        'errorMsg.axios': 'Send request error',
        'msg.wait': 'Adding subordinate agents...',
        'msg.succ': 'Add subordinate successfully',
        'res.code.1' : 'The parameter does not conform to the rules',
        'res.code.2' : 'The account already exists',
        'res.code.3' : 'Error adding',
        'res.code.un': 'Failed adding',
    },
    //[Recharge/Recycling] Agent Amount
    'agentListChangeMoney' : {
        'form.money' : 'Change amount:',
        'form.moneyType.label' : 'Money Type:',
        'form.moneyType.1' : '(balance) registration',
        'form.moneyType.2' : '(balance) recharge',
        'form.info.label' : 'Subordinate information:',
        'form.info.reg' : 'Register',
        'form.info.rec' : 'Recharge',
        'form.changeType.label' : 'Operation type',
        'form.changeType.addMoney' : 'Recharge subordinate',
        'form.changeType.recovery' : 'Recover subordinate',
        'form.submit' : 'Submit request',
        'form.return' : 'Cancel operation',
        'openDialog.user':'Username:',
        'submit.error.money': 'The amount is incorrect',
        'submit.succ': 'Successful execution!',
        'submit.code.un': 'Unknown error',
        'submit.code.1': '[changType/moneyType/moneyType] is incorrect',
        'submit.code.2': 'agentId is incorrect',
        'submit.code.3': 'The proxy does not exist',
        'submit.code.4': 'Insufficient balance',
        'submit.code.5': 'Execution error',
        'submit.code.6': 'Not your subordinate',
    },
    //[Proxy list] filter
    'agentList-filter':{
        'html.el-dialog.title': 'Please enter filter criteria',
        'html.el-form-item.label.time': 'Registration time',
        'html.el-form-item.label.username':'Proxy account',
        'html.el-form-item.label.name': 'name',
        'html.el-form-item.label.nickname': 'Notes',
        'html.span.tips': 'Tips: Only need to fill in one filter!',
        'html.el-button.submit': 'Filter',
        'html.el-button.return': 'Cancel',
        'html.el-button.reset': 'Clear',
        'submit.error.filter': 'Please set the filtering criteria',
        'submit.wait': 'Filtering agents...',
    },
    //[Proxy list]Proxy information[View/Edit]
    'agentList-info':{
        'html.el-form-item.label.leader': 'Supervisor:',
        'html.el-form-item.label.username':'Username:',
        'html.el-form-item.label.password':'password:',
        'html.el-form-item.label.name':'name:',
        'html.el-form-item.label.nickname':'Remarks:',
        'html.el-form-item.label.contact':'Contact:',
        'html.el-form-item.label.status':'Status:',
        'html.el-radio.1': 'Available',
        'html.el-radio.0': 'Disabled',
        'html.el-form-item.label.time':'time:',
        'html.el-form-item.label.login_time':'Login:',
        'html.el-form-item.label.reg_time':'Registration:',
        'html.el-form-item.label.money_reg':'balance[registration]:',
        'html.el-form-item.label.money_rec':'balance[recharge]:',
        'html.el-button.submit': 'Submit modification',
        'html.el-button.return': 'Cancel modification',
        'submit.tips': 'You have not made any changes, no need to submit a request',
        'submit.wait': 'Submitting data..',
        'submit.successful': 'Modified successfully!',
        'submit.res.code.2': 'The modified data does not conform to the rules',
        'submit.fail': 'Execution error',
    },
    // proxy list
    'agentList':{
        'html.el-button.refresh': 'Refresh the page',
        'html.el-button.viewTableData.1': 'Show all',
        'html.el-button.viewTableData.2': 'Display filter',
        'html.el-button.openFilter': 'Filter data',
        'html.el-checkbox.AgentDataType': 'Cross-level operation',
        'html.el-table-column.label.username':'Account',
        'html.el-table-column.label.name':'name',
        'html.el-table-column.label.contact':'Contact details',
        'html.el-table-column.label.nickname':'Remarks',
        'html.el-table-column.label.money_reg': 'balance [registration]',
        'html.el-table-column.label.money_rec': 'Balance [recharge]',
        'html.el-table-column.label.leader': 'Superior',
        'html.el-table-column.label.reg_time': 'Registration time',
        'html.el-table-column.label.login_time': 'Last login',
        'html.el-table-column.label.status': 'Status',
        'html.el-table.template.status.1': 'Available',
        'html.el-table.template.status.0': 'Unavailable',
        'html.el-table.tools': 'Management',
        'html.el-dropdown-item.tableMenu.1': 'Edit information',
        'html.el-dropdown-item.tableMenu.2': 'Recharge balance',
        // 'html.el-dropdown-item.tableMenu.3':'Log query',
        'html.el-dropdown-item.tableMenu.4': 'Recycle balance',
        'html.el-dropdown-item.tableMenu.5':'Query card secret',
        'html.el-dropdown-item.tableMenu.6':'User management',
        'html.el-dropdown-item.tableMenu.7':'Log query',

        'getAgentList.wait': 'Loading agent list...',
        'getAgentList.error': 'Loading agent data error',
        'getAgentList.tips.num': 'Agent list loaded quantity:',
        'viewTableData.wait': 'Switching..',
        'viewTableData.tips': '[Only display the first 200 items] Screening completed, the number of matching results:',
        'changeAgentDataType.wait': 'Switching cross-level data...',

        'html.el-button.outputData': 'Export data',
        'updateTable.message': 'You have changed the user data, do you want to update the display table data?',
        'updateTable.title': 'The page is about to be refreshed',
        'updateTable.catch': 'You have canceled refreshing the table, the latest data will be displayed after you refresh',
        'updateArr.tips':' Table data has been updated ',
        'outputData.message': 'Please select the type to export',
        'outputData.confirmButtonText': 'Export all',
        'outputData.cancelButtonText': 'Export check',
        'makeOutPutUserData.error.length': 'The export data is empty, please check',
        'makeOutPutUserData.tips.length': 'Generating export data, data length:',
    },

    // modify your own password page
    'setSelfNewPass':{
        'html.el-form-item.newPass': 'New Password',
        'html.el-form-item.tempPass': 'Secondary confirmation',
        'html.button.submit': 'Submit request',
        'html.button.reset': 'Reset data',

        'submit.error.length': 'Please enter the password to be set',
        'submit.error.tempPass': 'The passwords entered twice are inconsistent, please check',
        'submit.wait': 'Submitting request...',
        'submit.successful': 'executed successfully',
    },

    //Subordinate agent user list
    'subordinateUsers':{
        'moveUserToSelf.error.userList': 'Please select the user to operate',
        'moveUserToSelf.error.max': 'The number of operations exceeds the maximum limit',
        'moveUserToSelf.wait': 'Submitting the request, please wait...[*Do not close this page during execution]',
        'moveUserToSelf.res.code.1': 'Some users do not exist/some users are not owned by you',
        'moveUserToSelf.res.code.2': 'Inquiry about agent information is abnormal',
        'moveUserToSelf.res.code.3': 'Execution error',
        'moveUserToSelf.res.code.4': 'Execution error',
        'moveUserToSelf.res.code.5': 'Exceeded quantity limit',
        'moveUserToSelf.res.code.6': 'Submission parameters are incorrect',
        'moveUserToSelf.res.code.7': 'The agent is not your subordinate',
        'moveUserToSelf.res.code.100': 'The execution is successful, please refresh the page manually',
        'getAgentUserList.wait': 'Getting user list...',
        'getAgentUserList.error.userData': 'Get user list error',
        'getAgentUserList.res.code.1': 'The query agent information is abnormal',
        'getAgentUserList.res.code.2': 'Not your subordinate agent',
        'watchFilterData.success': 'Screening completed! Rendering data.. Number of matching results:',

        'html.el-button.refresh': 'Refresh the page',
        'html.el-button.return': 'Return to superior',
        'html.el-button.filter': 'Filter data',
        'html.el-button.moveUser': 'Remove the selected user',
        'html.el-popconfirm.title': 'Do you want to perform the action?',
        'html.el-tag.agent': 'Current agent',
        'html.el-table-column.label.id': 'Number',
        'html.el-table-column.label.username':'Account',
        'html.el-table-column.label.nickname':'Remarks',
        'html.el-table-column.label.name':'name',
        'html.el-table-column.label.money': 'balance',
        'html.el-table-column.label.login_time': 'Last login',
        'html.el-table-column.label.reg_time': 'Registration time',
        'html.el-table-column.label.status': 'Status',
    },
};
export default lang;