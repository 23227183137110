const lang = {
    //代理日志
    'agentLog':{
        'html.el-button.refresh':'刷新页面',
        'html.el-tag.agent':'查询代理商',
        'html.el-table.label.id':'编号',
        'html.el-table.label.time':'时间',

        'html.el-table.label.object':'操作目标',
        'html.el-table.label.type':'消息类型',
        'html.el-table.label.attach':'附带内容',

        'getAgentLog.wait':'正在请求代理日志..',
        'getAgentLog.error.data':'查询代理商数据异常',
        'getAgentLog.error.length':'查询代理商日志为空',

        'logType.1':'新增下级代理商',
        'logType.2':'修改下级代理信息',
        'logType.3':'修改自己密码',
        'logType.4':'充值下级余额[注册/充值]',
        'logType.5':'回收下级余额[注册/充值]',
        'logType.6':'上级充值余额[注册/充值]',
        'logType.7':'上级回收余额[注册/充值]',
        'logType.8':'迁移卡密',
        'logType.9':'回迁卡密',
        'logType.10':'批量生成卡密',
        'logType.11':'回收卡密',
        'logType.12':'[用户余额]充值',
        'logType.13':'[用户余额]回收',
        'logType.14':'批量充值用户余额',
        'logType.15':'[单个/批量]回收用户',
        'logType.16':'[单个/批量]注册用户',
        'logType.17':'修改用户信息',
        'logType.18':'用户所属代理商变更[迁移用户/回收用户]',
        'logType.un':'*?未知日志类型[请联系客服人员]',
        //易语言日志类型[大于100的]
        'logType.100':'新增下级代理商',
        'logType.101':'总代发放',
        'logType.102':'授权用户次数',
        'logType.103':'分配帐号额度',
        'logType.104':'增加帐号额度',
        'logType.105':'生成注册码',
        'logType.106':'生成次数码',
        'logType.107':'分配额度帐号',
        'logType.108':'余额转码[注册/充值]码 ',
        'logType.109':'增加次数额度',
        'logType.110':'分配额度次数天数',
        'logType.111':'余额转码次数天数充值码',
        'logType.112':'迁移用户',
        'logType.113':'[注册码]回迁卡密',
        'logType.114':'[注册码] [新增]回迁卡密',
        'logType.115':'回迁用户',
        'logType.116':'注册码下拨卡密',
        'logType.117':'充值码下拨卡密',
        'logType.118':'[充值码]回迁卡密',
        'logType.119':'充值码新增回迁卡密数量',
        'logType.120':'用户充值',
        'logType.121':'回收下级额度',
        'logType.122':'上级回收额度',
        'logType.123':'回收自己卡密',
        'logType.124':'回收用户余额',
        'logType.125':'批量充值用户',
    },
};
export default lang;