const lang = {
    // Card secret list
    'keyList':{
        'html.el-button.filter': 'Filter data',
        'html.el-button.refresh':'refresh page',
        'html.el-tag.user': 'Operation account',
        'html.el-table-column.label.id': 'Barcode',
        'html.el-table-column.label.type': 'type',
        'html.el-table-column.label.status': 'Status',
        'html.el-table-column.label.money':'Amount',
        'html.el-table-column.label.nickname':'Remarks',
        'html.el-table-column.label.use': 'Use account',
        'html.el-table-column.label.time': 'time',
        'scope.row.type.reg':'Recharge code',
        'scope.row.type.rec':'Registration code',

        'initKeyList.wait': 'Loading data...',
        'changeTypeAndStatus.wait': 'Changing data source..',
        'getTableTotal.error.total': 'Initialization table data error [Total]',
        'getTableTotal.wait': 'Rendering table..',
        'getTableData.error.render': 'Rendering card secret list error',
        'getStatusStr.1': 'Used',
        'getStatusStr.default': 'Unused',
        'recvFilterTableData.tips.successful': 'Filtering completed, number of results:',
        'recvFilterTableData.tips.max':'[Maximum display top 500]',
        'recvFilterTableData.error.length':'Screening is completed! No qualified results',
        'makePdf.tips.wait':'It is generating printing data, which may take some time ...',
    },

    //Migrate Kami [self->subordinate agent]
    'keyMove':{
        'submit.res.code.1': 'Submit parameter error [password list]',
        'submit.res.code.2': 'Exceeded the maximum number limitation',
        'submit.res.code.3': 'The new reseller does not exist',
        'submit.res.code.un': 'Unknown error',
        'submit.res.data.state.100': '[√] Successfully executed',
        'submit.res.data.state.1': '[×] Code does not exist',
        'submit.res.data.state.2': '[×] Code is incorrect',
        'submit.res.data.state.3':'[×]Execution error',

        'html.el-select.placeholder': 'Migrated new agency account',
        'html.el-input.placeholder': 'Please enter the code to be migrated, one per line',
        'html.el-tag.tips': 'Tips: It takes a few seconds to migrate data, please wait patiently and do not submit repeatedly!',
        'html.el-radio-group.label.1':'Code',
        'html.el-radio-group.label.2': 'Barcode',
        'html.el-button.submit': 'Submit request',
        'html.el-button.reset': 'Clear the form',
        'html.el-popconfirm.title': 'Submit the request?',
        'submit.error.newAgent': 'Please select a new agent account',
        'submit.error.keyList': 'Please enter the key to be migrated',
        'submit.wait': 'Executing request..',
        'submit.jump.wait': 'Execution completed, jumping to the result page..',
        'submit.ready.error': 'The execution is completed, but the parsing result queue failed',
        'submit.jump.title':'Migration result of code',
        'getAgentList.wait': 'Loading agent list...',
        'getAgentList.error.agentList': 'Loading agent list exception',
        'getAgentList.error.null': 'You have no subordinate agents',
    },

    //Generate code in batches
    'keyMake':{
        'onSubmit.res.code.100': 'Executed successfully',
        'onSubmit.res.code.1': 'The proxy account does not exist',
        'onSubmit.res.code.2': 'Insufficient balance',
        'onSubmit.res.code.3': 'Submit parameters do not conform to the rules',
        'onSubmit.res.code.4': 'Submit parameters do not conform to the rules',

        'html.button.submit': 'Submit request',
        'html.button.reset': 'Refresh the page',
        'html.el-form-item.keyNum': 'Number of Generations',
        'html.el-form-item.money': '*Code amounts',
        'html.el-form-item.nickname': 'Code remark',
        'html.el-input .placeholder': '(optional) remarks for each code',
        'html.el-form-item.type': 'code type',
        'html.el-radio.1':'Registration code',
        'html.el-radio.2':'recharge code',
        'html.el-tag.reg': 'Register',
        'html.el-tag.rec': 'Recharge',
        'html.span.tips.title':'[money] Code amount limited:',
        'html.span.tips.reg': 'Registration code: 1~50',
        'html.span.tips.rec': 'Recharge code: 1~5000',

        'getSelfMoney.error': 'Checking balance is abnormal',
        'onSubmit.error.keyNum': 'Exceeded quantity limitation [1~2000]',
        'onSubmit.error.money': '(money) code amount[1~5000]',
        'onSubmit.wait': 'Executing request...',
    },
    //Batch recycling card secret [own card secret]
    'recovery':{
        'submit.res.state.title': 'Recycling code results',
        'submit.res.state.100': '[√] Successfully executed',
        'submit.res.state.1': '[×] Code does not exist',
        'submit.res.state.2': '[×]The agent does not exist',
        'submit.res.state.3': '[×] Code has been used',
        'submit.res.state.4': '[×] The account number of the operating agent is inconsistent with the agent to which code belongs',
        'submit.res.state.5': '[×]Execution error',

        'html.el-input': 'Please enter the code to be recycled, one per line',
        'html.el-radio-group.label.1':'Code',
        'html.el-radio-group.label.2': 'Barcode',
        'html.el-tag.placeholder.tips.wait': 'Tips: It takes a few seconds to recycle data, please be patient and do not submit repeatedly!',
        'html.el-tag.placeholder.tips.keyStatus':'*Note: Please make sure that the key to be recycled has not been used!',
        'html.el-button.submit': 'Submit request',
        'html.el-button.reset': 'Clear the form',
        'html.el-popconfirm.title': 'Submit the request?',

        'submit.errot.keyList': 'Please enter the key to be migrated',
        'submit.wait': 'Executing request..',
        'submit.result.jump': 'Execution completed, jumping to the result page..',
        'submit.result.error': 'The execution is completed, but the parsing result queue failed',
    },
    //[card secret list] filter
    'keyList-filter':{
        'html.el-form-item.key': 'Code',
        'html.el-form-item.id': 'Barcode',
        'html.el-form-item.use': 'user',
        'html.el-form-item.time': 'time',
        'html.el-tag.tips': 'Tips: Fill in any condition!',
        'html.button.submit':'Filter data',
        'html.button.return': 'Cancel operation',
        'html.button.resetForm': 'Clear the form',

        'submit.error.filterConfig': 'Please set the conditions to filter',
        'submit.wait': 'Preparing data..',
        'getFilterTableData.error.filter.1': 'Filter data error',
        'getFilterTableData.error.filter.2':'*Filter data error[2]',

        'html.el-form-item.nickname': 'Remarks',
    },
    //Return to Code [subordinate agent->self]
    'keyTakeBackKey':{
        'html.el-input.placeholder': 'Please enter the code that need to be migrated from the lower-level agent to your own, one per line',
        'html.el-tag.tips':'*Tips: It will take time to transfer data back, please be patient! Please make sure that Kami is your subordinate agent!',
        'html.el-button.submit': 'Submit request',
        'html.el-button.reset': 'Clear the form',
        'html.el-popconfirm.title': 'Do you want to perform an action?',

        'submit.error.keyList': 'Please enter the code to be transferred back',
        'submit.wait': 'Executing request..',
        'submit.jump.wait': 'Execution completed, jumping to the result page..',
        'submit.ready.error': 'The execution is completed, but the parsing result queue failed',
        'submit.jump.title': 'Returning the result of Code (subordinate agent->self)',

        'submit.res.code.1': 'Submit parameter eoor [code list]',
        'submit.res.code.2': 'Exceeded the maximum number limitation',

        'submit.res.code.un': 'Unknown error',
        'submit.res.data.state.100': '[√] Successfully executed',
        'submit.res.data.state.1': '[×] Query code information is abnormal',
        'submit.res.data.state.2': '[×] Code is incorrect',
        'submit.res.data.state.3':'[×]Execution exception',
    },

    //[Code list] data mode selection
    'keyList-modeMenu':{
        'html.el-button.type': 'Mode switch',
        'html.el-button.status': 'Type switch',
        'html.el-dropdown-item.changeType.1': '[type] registration code',
        'html.el-dropdown-item.changeType.2': '[type] recharge code',
        'html.el-dropdown-item.changeType.3': '[status] not used',
        'html.el-dropdown-item.changeType.4': '[Status] Used',
        'html.el-dropdown-item.changeType.5': '[status] all',
        'html.el-tag.type': 'Card state',
        'html.el-tag.status': 'Use type',

        'getTypeStr.type.1':'Registration code',
        'getTypeStr.type.2':'recharge code',
        'getTypeStr.type.3': 'All codes',
        'getTypeStr.type.default': 'Unknown type',

        'getStatusStr.status.0': 'Unused',
        'getStatusStr.status.1': 'Used',
        'getStatusStr.status.3': 'All codes',
        'getStatusStr.status.default':'abnormal status',
    },
    //[Card Secret List] Toolbar
    "keyList-ToolsMenu":{
        'html.el-dropdown-item.Refresh': 'Refresh the page',
        'html.el-dropdown-item.outputSelectKey':'Export Selected',
        'html.el-dropdown-item.moveKey': 'Migrate code',
        'html.el-dropdown-item.recoveryKey':'recovery code',
        'html.el-dropdown-item.takeBackKey': 'take Back the code',

        'getSelectArr.tips': 'Please select the code to operate',
        'outputSelectKey.wait': 'Exporting data...',
        'moveKey.wait': 'Preparing to migrate the card key..',
        'recoveryKey.wait': 'Preparing data..',
        'takeBackKey.wait': 'Preparing data..',
    },
    'makeKeyLog':{
        'html.DownLoadLog.Describe': 'Batch generated card secret records',
    },
    'keyDataToPdf':{
        'html.el-button.refresh': 'Refresh the page',
        'html.el-button.print': 'Print page',
        'html.el-button.makePdf': 'Generate PDF',

        'makePdf.wait': 'The file is being generated, please be patient...',
        'onMounted.error.keyData': 'Parsing data exception [0x001]',
        'onMounted.error.array': 'Parsing data exception [0x002]',
    },
};

export default lang;