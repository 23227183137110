const lang = {
    //user list
    'userList':{
        'html.el-button.refresh': 'Refresh the page',
        'html.el-button.view.all': 'Display all',
        'html.el-button.view.filter': 'Display filter',
        'html.el-checkbox.userDataType': 'Cross-level management',
        'html.el-table-column.label.agent': 'Agent',
        'html.el-table-column.label.username':'Account',
        'html.el-table-column.label.name':'name',
        'html.el-table-column.label.nickname':'Remarks',
        'html.el-table-column.label.reg_time': 'Registration time',
        'html.el-table-column.label.login_time': 'Last login',
        'html.el-table-column.label.money': 'balance',
        'html.el-table-column.label.contact':'Contact information',
        'html.el-table-column.label.status': 'Status',
        'html.el-table-column.label.status.1': 'Available',
        'html.el-table-column.label.status.default': 'disavailable',
        'html.el-table-column.label.tools': 'Management',
        'html.el-dropdown-item.tableMenu.1': 'Edit information',
        'html.el-dropdown-item.tableMenu.2': 'Recharge balance',
        'html.el-dropdown-item.tableMenu.3': 'Recycle balance',
        'html.el-dropdown-item.tableMenu.4':'Query download',
        'html.el-dropdown-item.tableMenu.5':'recharge record',

        'getUserList.wait': 'Loading user list...',
        'getUserList.error.userList': 'User list loading error',
        'getUserList.error.data': 'The request returned data error',
        'getUserList.tips.userNum': 'User List Loaded Quantity:',
        'editUser.error.userid': 'User id is abnormal',
        'viewTableData.wait': 'Switching..',
        'viewTableData.tips.filterNum': '[Only display the first 200 items] The filter is completed, the number of matching results:',
        'getUserMoneyTime.timeout': 'Expired',
        'getUserMoneyTime.day': 'day',
        'changeUserDataType.wait': 'Switching user data source...',

        'html.el-button.outputData': 'Export data',
        'updateTable.message': 'You have changed the user data, do you want to update the display table data?',
        'updateTable.title': 'The page is about to be refreshed',
        'updateTable.catch': 'You have canceled refreshing the table, the latest data will be displayed after you refresh',
        'updateArr.tips':' Table data has been updated ',
        'outputData.message': 'Please select the type to export',
        'outputData.confirmButtonText': 'Export all',
        'outputData.cancelButtonText': 'Export check',
        'makeOutPutUserData.error.length': 'The export data is empty, please check',
        'makeOutPutUserData.tips.length': 'Generating export data, data length:',
    },

    //User list - menu
    'userList-menu':{
        'menu.recovery': 'Recover user',
        'menu.move': 'Migrate users',
        'menu.filter': 'Filter users',
        'menu.output': 'Export user',
        'menu.addUserMoney': 'Bulk recharge',
        'error.length': 'Please select the user to operate',
        'makeOutPutData.wait': 'Generating export data, please wait',
        'makeOutPutData.successful': 'The data has been generated',
        'makeOutPutData.length': 'Please confirm the user to be exported',
        'error.max': 'Operation user exceeds the limit'
    },

    //Bulk registered users
    'userBulk':{
        'form.user':'Account Prefix',
        'form.start': 'Suffix start',
        'form.padding': 'Suffix length',
        'form.num': 'Number of registrations',
        'form.name':'registered name',
        'form.contact': 'Contact information',
        'form.tips.user': 'Register account scope',
        'form.submit': 'Submit request',
        'form.reset': 'Reset data',
        'el-tag.tips': 'Tips: Registration may take a few seconds, please be patient! Do not click repeatedly!',
        'el-tag.tips.warning': 'Note: User information is only available in English numbers, account length limitation: 1~16',
        'submit.error.form': 'Please fill in the complete information',
        'submit.error.num': 'The registration range only allows 1~2000',
        'submit.wait': 'Submitting the request, please do not click repeatedly..',
        'submit.success': 'Bulk registration succeeded',
        'submit.res.code.un': 'Failed to execute, unknown error!',
        'submit.res.code.1': '[Code] registration code is insufficient',
        'submit.res.code.2': 'Execution error [db]',
        'submit.res.code.3': 'The code module is abnormal',
        'submit.res.code.4': 'Duplicate account',
        'submit.res.code.500': 'Submitted parameters do not conform to the rules',
        'openDownLoadUserData.wait': 'Generating export user information..',
        'openDownLoadUserData.error.userData': 'The execution is completed, but the registration account information is abnormal. Please go to the user list to view the user information that has been successfully registered',
        'openDownLoadUserData.successful': 'User information has been generated',
    },
    //Bulk migration of users
    'userMove':{
        'html.select.placeholder': 'Migrated new agent',
        'html.button.add': 'Add user',
        'html.button.reset': 'Clear user',
        'html.button.submit': 'Execute migration',
        'html.el-popconfirm.submit': 'Do you want to migrate users?',
        'html.tag.userNum': 'Number of users',
        'html.dialog.title': '[Add user] one per line',
        'html.dialog.placeholder': 'Please enter the user to be migrated, one per line',
        'html.dialog.footer.addUser': 'Add',
        'html.dialog.footer.return': 'Cancel',
        'addUser.error.user': 'Please enter the user to be added',
        'submit.error.user': 'Please enter the user to be migrated',
        'submit.error.agent': 'Please select the new agent account to be migrated',
        'submit.wait': 'Executing...please wait',
        'submit.success': 'The execution was successful',
        'submit.res.errorCode.1': 'Some users do not exist / some users do not belong to you',
        'submit.res.errorCode.2': 'Reseller does not exist',
        'submit.res.errorCode.3': 'Execution exception',//[update] Failed to generate Sql statement
        'submit.res.errorCode.4': 'Failed to execute [db]',
        'submit.res.errorCode.5': 'The number of migrated users exceeds the limit',
    },
    //Recycle users in batches
    'recovery':{
        'html.el-input.placeholder': 'Please enter a list of users waiting for operation..',
        'html.el-tag.tips': 'Tips: The migration process may take a few seconds, please be wait..',
        'html-el-popconfirm.submit': 'Whether to perform bulk recycling of users?',
        'html.button.submit': 'Submit data',
        'html.button.reset': 'Clear data',
        'html.el-tag.userNum': 'Number of users',
        'submit.error.user': 'Please enter the user to be migrated',
        'submit.tips.wait': 'Users are being migrated..please wait',
        'submit.res.code.un': '*Unknown error',
        'submit.res.code.100': 'Successful execution, parsing the result. Please wait',
        'submit.res.code.1': 'Error parsing user list',
        'submit.res.code.2': 'Exceeded quantity limit [20]',
        'submit.error.result': 'The execution is successful, but the parsing result list is abnormal',

        'submit.title':'Recycle account results',
        'submit.res.state.100': 'Succeed',
        'submit.res.state.1': '[Failed] User does not exist',
        'submit.res.state.2': '[Failed] The user has exceeded the recovery period [cut more than 7 days] ',
        'submit.res.state.3': '[Failed] Failed to find the used code',
        'submit.res.state.4': '[Failed] Reset code failed',
        'submit.res.state.5': '[Failed] Write error [db]',
        'submit.res.state.un': '[Failure] Unknown Error [Exception]',
        'submit.jump.wait': 'Jumping the result page, please wait...',
    },
    //[User list] (recharge/recovery) user balance
    'userList-changeMoney':{
        'submit.res.code.100': 'Executed succeed',
        'submit.res.code.1': 'User does not exist',
        'submit.res.code.2': 'The query agent information is abnormal',
        'submit.res.code.3': 'The user which belongs to is incorrect',
        'submit.res.code.4': '[Agent] has insufficient balance',
        'submit.res.code.5': '[User] has insufficient balance',
        'submit.res.code.6': 'Write error [db]',
        'submit.res.code.7': 'Submit parameters do not meet the rules [1~5000]',
        'html.form.username':'Account',
        'html.form.money': 'Amount',
        'html.form.type': 'Operation type',
        'html.form.type.add': 'Recharge balance',
        'html.form.type.reduce': 'Recycle balance',
        'html.form.selfMoney': 'Current user balance',
        'html.button.return': 'Cancel operation',
        'html.button.submit': 'Submit request',
        'submit.wait': 'Executing request..',
        'getUserInfo.error': 'Loading user balance is abnormal',
    },
    //User download record
    'userDownLoad':{
        'html.button.return': 'Return to superior',
        'html.button.refresh': 'Refresh the page',
        'html.button.outFileByUserDownload':'Export records',
        'getCount.wait': 'Getting the total number of records..',
        'getCount.error': 'Getting the total number of records error',
        'getTableData.wait': 'Querying download records..',
        'getTableData.error.1': '[*]Get download records error',
        'getTableData.error.2': '[**] Get download records error',
        'outFileByUserDownload.wait': 'Preparing to export data...',
        'outFileByUserDownload.successful': 'The export of data has been completed',

    },
    //[user list] filter
    'userList-filter':{
        'html.el-form-item.label.time': 'Registration time',
        'html.el-form-item.label.username':'username',
        'html.el-form-item.label.name': 'name',
        'html.el-form-item.label.nickname': 'Notes',
        'html.span.tips': 'Tips: Only need to fill in one filter condition!',
        'html.el-button.submit': 'Filter',
        'html.el-button.return': 'Cancel',
        'html.el-button.reset': 'Clear',

        'submit.error.filterValue': 'Please set the conditions that need to be filtered!',
        'submit.wait': 'Filtering users...',
    },

    //[User list]Edit/View personal information
    'userList-info':{
        'html.el-form-item.label.agent': 'The agent',
        'html.el-form-item.label.username':'username',
        'html.el-form-item.label.password':'password',
        'html.el-form-item.label.name': 'name',
        'html.el-form-item.label.nickname': 'Notes',
        'html.el-form-item.label.contact': 'Contact information',
        'html.el-form-item.label.status': 'Status',
        'html.el-radio-group.status.1': 'Available',
        'html.el-radio-group.status.0': 'Disabled',
        'html.el-form-item.label.time': 'time',
        'html.el-tag.login_time': 'Login',
        'html.el-tag.reg_time': 'Register',
        'html.el-form-item.label.money': 'balance',
        'html.el-button.submit': 'Submit request',
        'html.el-button.return': 'Cancel modification',

        'submit.tips': 'You have not made any changes, no need to submit a request',
        'submit.wait': 'Submitting data..',
        'submit.successful': 'Modified successfully!',
        'submit.res.error.2': 'The modified data does not conform to the rules',
        'submit.res.error.default': 'Execution error',
    },

    //The records of user uses the recharge code
    'userUseRecKey':{
        'html.el-button.go.-1': 'Return to superior',
        'html.el-button.go.0': 'Refresh page',
        'html.el-tag.user': 'Query user',
        'html.el-table-column.label.id': 'Barcode',
        'html.el-table-column.label.key':'Code',
        'html.el-table-column.label.money':'Amount',
        'html.el-table-column.label.time': 'time',

        'getUseKeyData.wait': 'Loading data..',
        'getUseKeyData.error.res': 'Query record error',
        'getUseKeyData.error.length': 'The user has no record',
    },

    //User bulk recharge
    'userAddMoneyBatch':{
        'submit.code.100': 'executed successfully',
        'submit.code.1': 'The parsing of the recharge list is abnormal',
        'submit.code.2': 'Some users do not meet the rules [does not exist/belongs to incorrect agency]',
        'submit.code.3': 'Exceeded quantity limitation [1000]',
        'submit.code.4': 'Insufficient agent balance',
        'submit.code.5': 'Execution error [5]',
        'submit.code.6': 'Execution error [6]',
        'submit.error.list': 'Please enter the list of users to be recharged',
        'submit.wait': 'Submitting request...',

        'html.input.placeholder': 'Please enter the list of users who need to recharge~',
        'html.popconfirm.title': 'Do you want to perform the operation?',
        'html.button.submit': 'Submit request',
        'html.button.reset': 'Reset data',
        'html.tag.userNum': 'Number of users',
        'html.tag.money': 'Balance [recharge]',
        'html.tag.tips':'[one line per line] Input format: username----recharge amount (integer)',
    },
    'userList-filterMoneyTime':{
        'html.button.filter': 'Filter balance',
        'html.dropdown-item.day': 'Filter days',
        'html.dropdown-item.overdue': 'Filter expired',
        'html.button.return': 'Cancel operation',
        'html.button.submit': 'Execute filter',
        'filterUser.wait': 'Filtering users...',
        'filterUser.success':'Number of eligible users:',
    },
    'BatchRegUserLog':{
        'html.DownLoadLog.Describe': 'Batch registered user records'
    }
};
export default lang;