const lang = {
    //头部组件
    'memberHeader':{
        'title':'后台管理系统',
        'username':'账号',
        'index':'个人中心',
        'loginOut':'退出登录',
        'setSelfNewPass':'修改密码',
    },
    //导航条组件
    'Navbar':{
        //栏目
        'items':{
            'nav.user':'用户管理',
            'nav.agent':'代理管理',
            'nav.key':'卡密管理',
            'nav.agentReport':'代理统计',
            'nav.log':'日志查询',
            'nav.userReport':'用户统计',
            'nav.BrandModelReport':'型号统计',
        },
        //子页面
        'pages':{
            'user.userList':'用户管理',
            'user.moveUser':'迁移用户',
            'agent.agentList':'下级管理',
            'agent.addAgent':'添加下级',
            'user.bulkUser':'批量注册',
            'user.recovery':'批量回收',
            'key.keyList':'卡密管理',
            'key.makeKey':'生成卡密',
            'key.moveKey':'迁移卡密',
            'key.recovery':'回收卡密',//自己的卡密
            'agentReport.userNum':'用户新增',
            'agentReport.agentNum':'代理新增',
            'agentReport.cut':'代理切割',
            'userReport.cut':'用户切割',
            'userReport.KeyByRec':'用户充值',
            'userReport.model':'热门型号',
            'userReport.brand':'热门品牌',
            'log.agentLog':'代理日志',
            'user.userAddMoneyBatch':'批量充值',
            'user.BatchRegUserLog':'注册日志',
            'key.makeKeyLog':'卡密日志',
        },
    },
    //个人中心
    'indexData':{
        'view.user':'用户数量',
        'view.agent':'下级代理数量',
        'view.key':'充值码总数',
        'view.money.reg':'[余额]注册',
        'view.money.rec':'[余额]充值',
        'view.online':'今日在线人数',
        'msg.loadSuccess':'加载完成',
    },
};
export default lang;